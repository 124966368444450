import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Banner, Stack } from '@patreon/studio';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "how-to-migrate-simple-strings"
    }}>{`How to migrate simple strings`}</h1>
    <p>{`Because humans are the most likely target to be translating our messages, we
need a robust way to give them tools to give accurate translations. ICU messages
are one way to do this and they give translators a lot of more complex ways to
get the full understanding of the message.`}</p>
    <p>{`We will first consider simple strings those that are easy to migrate to Stele.
Ones that do not require advanced ICU configs to do.`}</p>
    <p>{`If you want to follow along in your `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{` we will be using the following
configuration for Stele:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
    "@patreon/stele/dist/plugin",
    {
        "propName": "intl",
        "requireSpecificComponent": false,
        "developmentLanguage": true,
        "replace": true
    }
]
`}</code></pre>
    <p>{`For more information on why we have `}<inlineCode parentName="p">{`developmentLanguage`}</inlineCode>{` and `}<inlineCode parentName="p">{`replace`}</inlineCode>{` set to
true `}<a parentName="p" {...{
        "href": "/how-to/development"
      }}>{`visit the development docs.`}</a></p>
    <h2 {...{
      "id": "jsx"
    }}>{`JSX`}</h2>
    <p>{`Migrating JSX is fairly easy. Let's assume we have a component that has a simple
name with an argument. Migrating this is as easy as adding your
internationalization prop, along with the user's locale.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// before
function MyComponent(props) {
    return <p>Hello, my friend</p>
}

// after
function MyComponent(props) {
    return <p intl={locale}>Hello, my friend</p>
}
`}</code></pre>
    <p>{`This creates the message:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "Hello, My friend": "Hello, my friend"
}
`}</code></pre>
    <h3 {...{
      "id": "adding-in-a-variable"
    }}>{`Adding in a variable`}</h3>
    <p>{`Adding in variables does not change the solution above! Stele does some things
under the hood to create the message intelligently.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// before
function MyComponent(props) {
    return <p>Hello, my friend {props.friendName}</p>
}

// after
function MyComponent(props) {
    return <p intl={locale}>Hello, my friend {props.friendName}</p>
}
`}</code></pre>
    <p>{`This creates the message:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "Hello, My friend {friendName}": "Hello, my friend {friendName}"
}
`}</code></pre>
    <p>{`As you can see, Stele knew to turn `}<inlineCode parentName="p">{`props.friendName`}</inlineCode>{` in to `}<inlineCode parentName="p">{`friendName`}</inlineCode>{` for the
message.`}</p>
    <Stack mdxType="Stack">
    <Banner inline={true} variant={Banner.Variant.Warning} mdxType="Banner">
        <b>Warning:</b> If you use two member expressions like{' '}
        <inlineCode>props.friendName</inlineCode> and{' '}
        <inlineCode>oldProps.friendName</inlineCode> this will produce a message
        that looks incorrect.
    </Banner>
    <Banner inline={true} variant={Banner.Variant.Info} mdxType="Banner">
        <b>Suggestion:</b> Instead rename the{' '}
        <inlineCode>oldProps.friendName</inlineCode> to be a new variable name
        llike <inlineCode>previousFriendName</inlineCode>.
    </Banner>
    </Stack>
    <h2 {...{
      "id": "translate"
    }}>{`Translate`}</h2>
    <p>{`You use the translate function to translate messages not used inside react. Say
inside of a prop, or outside of the react context all together.`}</p>
    <p>{`Say you had a modal with just a title prop on it. Translating this is
fortunately easy, and similar to the previous solution.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// before
function SimpleTranslateComponent(props) {
    return <Modal title="Are you sure?" onConfirm={confirm} />
}

// after
function SimpleTranslateComponent(props) {
    return (
        <Modal title={translate(locale, 'Are you sure?')} onConfirm={confirm} />
    )
}
`}</code></pre>
    <Banner inline={true} variant={Banner.Variant.Info} mdxType="Banner">
    <b>Suggestion:</b> It is better and more maintainable to rewrite something
    in JSX vs as a string. So if you can, refactor props to take ReactNodes
    instead of just strings. This way you can put a{' '}
    <inlineCode>Text</inlineCode> component inside that prop with your{' '}
    <inlineCode>intl</inlineCode> prop instead.
    </Banner>
    <h3 {...{
      "id": "using-variables-inside-translate"
    }}>{`Using variables inside translate`}</h3>
    <p>{`Using variables inside the translate function is a little more difficult than
using the JSX route by itself. The second argument to the translate function is
just a regular ICU string. Therefore, to use variables you must use it like a
normal ICU string.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// before
function SimpleTranslateComponent(props) {
    return <Modal title={\`Are you use \${userName}?\`} onConfirm={confirm} />
}

// after
function SimpleTranslateComponent(props) {
    return (
        <Modal
            title={translate(locale, 'Are you sure {userName}?', {
                userName: userName,
            })}
            onConfirm={confirm}
        />
    )
}
`}</code></pre>
    <h2 {...{
      "id": "something-that-is-mostly-variables"
    }}>{`Something that is mostly variables`}</h2>
    <p>{`If something is mostly variables, with no user facing text in it Something like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<p>
    {creatorName}: {creatorType}
</p>
`}</code></pre>
    <p>{`While other languages might do this differently, in these cases I would mark
them as translatable on a case by case basis. Usually displaying some simple
piece of text like this that has no messages in it won't need to be translated.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      